<script>
import Form from '@/found/components/form';
import request from '@/found/utils/request';

export default {
  extends: Form,
  data() {
    return {
      rule: [],
      row: null,
    };
  },
  async created() {
    const rule = await this.getFormRule('order_download_form');
    this.reload(rule);
    if (this.formConfig.id) {
      request.get('/kms/kmsdirectthread/query', { id: this.formConfig.id }).then((res) => {
        this.setValue({
          ...res.result,
          orderDownloadWay: String(res.result.orderDownloadWay),
        });
        this.row = res.result;
      });
    }
  },

  methods: {
    setRule(v) {
      const item = v;
      if (item.field === 'orderType') {
        // 单据类型
        item.restful = '/kms/kmsAdmin/tenantry/direct/orderType/list';
        item.restfulParamsGetValue = {
          directId: 'directId',
        };
        item.optionsKey = { label: 'orderTypeName', value: 'orderType' };
      } else if (item.field === 'directId') {
        // 商超系统
        item.restful = '/kms/kmsdirectsystem/list';
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'directName',
        };
        item.optionsKey = { label: 'directName', value: 'id' };
      }
      return item;
    },
    // 提交
    submit() {
      const formData = this.getFormData();
      const param = {
        ...this.row,
        ...formData,
        fileClassify: '0',
        fileDownloadWay: '0',
      };
      request.post(this.formConfig.id ? ('/kms/kmsdirectthread/update') : ('/kms/kmsdirectthread/save'), param).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功',
          });

          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
  },
};
</script>
